import digital from '../../../imgs/capabilities/digital.svg';
import mentoring from '../../../imgs/capabilities/mentor.svg';
import softwareDev from '../../../imgs/capabilities/soft-dev.svg';

export const capabilities =
[
  {
    "image": digital,
    "heading": "World Class Digital Content",
    "text": "Through our exclusive collection of high quality digital content, we help learners across the world master programming skills in record time. " +
      "The majority of our creative work is offered to learners 100% FREE of charge.",
    "buttonLabel": "EXPLORE COURSES"
  },
  {
    "image": mentoring,
    "heading": "Personalized Mentorship",
    "text": "With decades of experience as software practitioners, we offer technical mentoring to learners interested in software development. " +
      "We focus on building the fundamental concepts before introducing software abstractions and frameworks to our learners.",
    "buttonLabel": "SEE MENTORING"
  },
  {
    "image": softwareDev,
    "heading": "Online Courses",
    "text": "We bring our unique perspective to educate learners with our free and paid online courses. " +
      "We have taught over 16000 learners with some of the biggest learning platforms. " +
      "We blend that experience with our creativity to educate how to build for the modern web.",
    "buttonLabel": "SEE OUR WORK"
  }
]
