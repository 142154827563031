import React from "react"
import Home from "../components/Home"
import { Routes } from "../shared/constants"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import {seoData} from "../shared/seoData";

export default class HomeContainer extends React.Component {
  onClickViewCourses = () => navigate(Routes.courses)
  onClickMeetTeam = () => navigate(Routes.team)
  onCourseClick = id => navigate(`${id}`)

  render() {
    let seo = seoData.homePage;
    return (
      <div>
        <SEO title={seo.title} description={seo.metaDescription}/>
        <Layout selectedTabValue={false}>
          <Home
            onClickViewCourses={this.onClickViewCourses}
            onClickMeetTeam={this.onClickMeetTeam}
            onCourseClick={this.onCourseClick}
          />
        </Layout>
      </div>
    )
  }
}
