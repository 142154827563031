import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import SectionP from '../../Common/SectionP';
import BannerImage from './BannerImage';
import Tag from './Tag';

export default function Banner({onClickViewCourses}) {
  const classes = useStyles();
  return <SectionP>
  <Grid container className={classes.bannerOnMobile} justify='center' alignItems="center">
    <Grid item xs={12} sm={12} md={7} lg={5} xl={5} className={classes.tagOnMobile}>
      <Tag onClickViewCourses={onClickViewCourses}/>
    </Grid>
    <Grid item xs={12} sm={12} md={5} lg={5} xl={4}>
      <BannerImage />
    </Grid>
  </Grid>
  </SectionP>
};

const useStyles = makeStyles(theme => ({
  bannerOnMobile: {
    [theme.breakpoints.between('xs', 'sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    }
  },
  tagOnMobile: {
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    }
  }
}));