import React from "react"
import { withStyles, Grid, Typography } from "@material-ui/core"
import { BannerData } from "../../../../shared/constants"
import { CallToAction } from "./CallToAction"

const Tag = ({ classes, onClickViewCourses }) => (
  <Grid container justify="center" className={classes.bannerTagContainer}>
    <Grid item xs={12} md={12}>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Typography variant={"h4"} className={classes.tagLine}>
            {BannerData.main}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10} className={classes.secondary}>
          <Typography variant="subtitle1">{BannerData.secondary}</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container>
            <Grid item xs={12}>
              <CallToAction classes={classes} onClickViewCourses={onClickViewCourses} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

const styles = theme => ({
  bannerTagContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  tagLine: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  secondary: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6),
  },
  letsChatContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
    },
  },
  letsChatHeadline: {
    paddingBottom: theme.spacing(1),
  },
})

export default withStyles(styles)(Tag)
