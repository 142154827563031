import React from "react"
import { Grid, makeStyles, Typography } from "@material-ui/core"
import SectionP from "../../Common/SectionP"
import SectionTitle from "../../Common/SectionTitle"
import { capabilities } from "./what-we-do-data"

const useStyles = makeStyles(theme => ({
  image: {
    height: "90%",
    width: "90%",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
  section: {
    backgroundColor: theme.palette.secondary.surface,
  },
  paddingBelowMe: {
    paddingBottom: theme.spacing(3),
  },
  overflow: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
}))


export default function WhatWeDo() {
  const classes = useStyles()
  return (
    <SectionP moreClasses={classes.section}>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <SectionTitle color="textPrimary">WHAT’S IN FOR ME?</SectionTitle>
        </Grid>

        <Grid item className={classes.overflow}>
          <Grid container spacing={6}>
            {capabilities.map((capability, key) => (
              <Grid item key={key} xs={12} md={4}>
                <WhatWeDoItem classes={classes} {...capability} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </SectionP>
  )
}

const WhatWeDoItem = ({ classes, image, heading, text, buttonLabel }) => {
  return (
    <Grid container alignItems={"center"} direction={"column"}>
      <Grid item className={classes.paddingBelowMe}>
        <img
          alt={heading}
          src={image}
          className={classes.image}
          loading={"lazy"}
        />
      </Grid>

      <Grid item className={classes.paddingBelowMe}>
        <Typography variant={"h6"}>{heading}</Typography>
      </Grid>

      <Grid item xs={10} className={classes.paddingBelowMe}>
        <Typography variant="subtitle1" align={'center'}>{text}</Typography>
      </Grid>

      {/*<Grid item>*/}
      {/*  <Button variant="outlined" color="primary">*/}
      {/*    {buttonLabel}*/}
      {/*  </Button>*/}
      {/*</Grid>*/}
    </Grid>
  )
}
