import { Grid, withStyles } from "@material-ui/core"
import React from "react"
import Banner from "./Banner"
import Technologies from "./Technologies"
import WhatWeDo from "./WhatWeDo"
import LatestContent from "./LatestContent"
import { getReviews } from "../../shared/reviews-data"
import Praises from "../Praises"

export const Home = (
  {
    classes,
    onClickViewCourses,
  }) => (
  <Grid container className={classes.root} justify="center">
    <Grid item xs={12}>
      <Banner onClickViewCourses={onClickViewCourses}/>
    </Grid>

    <Grid item xs={12}>
      <WhatWeDo/>
    </Grid>

    <Grid item xs={12}>
      <LatestContent/>
    </Grid>

    <Grid item xs={12}>
      <Technologies/>
    </Grid>

    <Grid item xs={12}>
      <Praises data={getReviews()}/>
    </Grid>
  </Grid>
)

const styles = theme => ({
  root: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
})

export default withStyles(styles)(Home)
