import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import banner from '../../../imgs/banner.svg';

const BannerImage = ({ classes }) => (
  <Grid container justify={'center'}>
    <Grid item className={classes.imageContainer}>
      <img src={banner} alt="bonsaiilabs_banner" className={classes.image} loading={"lazy"}/>
    </Grid>
  </Grid>
);

const styles = theme => ({
  imageContainer: {
    height: '60vh',
    width: '65vw',
    [theme.breakpoints.between('xs', 'md')]: {
      height: '45vh',
      width: '100vw'
    }
  },
  image: {
    height: '100%',
    width: '100%',
  }
});

export default withStyles(styles)(BannerImage);
