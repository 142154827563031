import { Button, Card, CardActionArea, CardMedia, Grid, makeStyles, Typography } from "@material-ui/core"
import SectionP from "../../Common/SectionP"
import SectionTitle from "../../Common/SectionTitle"
import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { navigate } from "gatsby"
import { getAuthorsWithImage, Routes } from "../../../shared/constants"
import AuthorsAvatar from "../../AuthorAvatars"

const useStyles = makeStyles(theme => ({
  overflow: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
  image: {
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
  latestCardContainer: {
    borderRadius: "12px",
    margin: theme.spacing(2),
    padding: "25px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.secondary.surface,
      boxShadow: `4px 4px 6px ${theme.palette.secondary.dark}`,
    },

    [theme.breakpoints.down("lg")]: {
      padding: "0px",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        boxShadow: `none`,
      },
    },
  },
  latestCard: {
    boxShadow: 3,
    width: 345,
    [theme.breakpoints.only("xs")]: {
      width: 290,
    },
    [theme.breakpoints.only("sm")]: {
      width: 400,
    },
  },
  viewAllArticles: {
    marginTop: theme.spacing(5),
  },
  latestCardTitle: {
    paddingTop: theme.spacing(2),
  },
  latestCardExcerpt: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  authorContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}))

export default function LatestContent() {
  const classes = useStyles()
  return (
    <StaticQuery
      query={query}
      render={data => {
        const articles = data.allMdx.edges

        return <SectionP>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <SectionTitle color="textPrimary">LATEST CONTENT</SectionTitle>
            </Grid>

            <Grid item className={classes.overflow}>
              <Grid container spacing={6} justify={"center"}>
                {articles.map((article, key) => {

                  const title = article.node.frontmatter.title
                  const pubDate = article.node.frontmatter.date
                  const authors = article.node.frontmatter.authors
                  const excerpt = article.node.excerpt
                  const slug = article.node.slug
                  const image = data.allFile.nodes.filter(node => node.name === slug)[0]

                  return <Grid item key={key} sm={7} md={4}>
                    <LatestContentCard
                      classes={classes}
                      cardImage={image}
                      authors={authors}
                      pubDate={pubDate}
                      title={title}
                      excerpt={excerpt}
                      onClick={() => navigate(slug)}
                      tabIndex={key}
                    />
                  </Grid>
                })}
              </Grid>
            </Grid>

            <Grid item className={classes.viewAllArticles}>
              <Button variant={"outlined"}
                      color={"primary"}
                      size={"large"}
                      onClick={() => navigate(Routes.blog)}
              >
                View All Articles
              </Button>
            </Grid>
          </Grid>
        </SectionP>
      }}/>
  )
}

const LatestContentCard = (
  {
    classes, cardImage, authors, pubDate, title, excerpt, onClick, tabIndex
  }) => {
  return (
    <div className={classes.latestCardContainer} onClick={onClick} onKeyDown={onClick} role={'button'} tabIndex={tabIndex}>
      <Card variant={"elevation"} className={classes.latestCard}>
        <CardMedia>
          <Img fluid={cardImage.childImageSharp.fluid}/>
        </CardMedia>

        <CardActionArea disableRipple={true}>
          <Grid container justify={"center"}>
            <Grid item xs={11}>
              <AuthorsAvatar
                authors={getAuthorsWithImage(authors)}
                classes={classes}
                aCaption={pubDate}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify={"center"}
            style={{ height: "20vh" }}>
            <Grid item xs={11} className={classes.latestCardTitle}>
              <Typography variant="h6" align={"left"}>{title}</Typography>
            </Grid>

            <Grid item xs={11} className={classes.latestCardExcerpt}>
              <Typography variant="caption"
                          align={"left"}
                          color={"textSecondary"}
              >
                {excerpt}
              </Typography>
            </Grid>

          </Grid>
        </CardActionArea>
      </Card>
    </div>
  )
}

const query = graphql`
{
  allMdx(
    filter: {fileAbsolutePath: {regex: "/(posts|articles)/"}},
    sort: {fields: frontmatter___date, order: DESC},
    limit: 3
  ) {
    edges {
      node {
        id
        slug
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          authors
        }
        excerpt(pruneLength: 140)
      }
    }
  }

   allFile(filter: { extension: { regex: "/(jpeg)/" } }) {
    totalCount
    nodes {
      name
      publicURL
      childImageSharp {
        fluid(maxWidth: 400) {
      ...GatsbyImageSharpFluid
        }
      }
    }
  }
}
`
