import { Grid } from "@material-ui/core"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import AvatarGroup from "@material-ui/lab/AvatarGroup"
import * as React from "react"

const AuthorsAvatar = ({ authors, classes, aCaption }) => {
  if (authors.length === 1) return <Grid container spacing={2} className={classes.authorContainer} alignItems={"center"}>
    <Grid item xs={2}><Avatar src={authors[0].image} alt={authors[0].name}/></Grid>
    <Grid item xs={6}>
      <Typography variant="body2">{authors[0].name}</Typography>
      {aCaption && <Typography variant="caption" color={'textSecondary'}>{aCaption}</Typography>}
    </Grid>
  </Grid>

  return <Grid container spacing={2} className={classes.authorContainer} alignItems={"center"} justify={"space-between"}>
    <Grid item xs={2}>
      <AvatarGroup>
        <Avatar alt={authors[0].name} src={authors[0].image}/>
        <Avatar alt={authors[1].name} src={authors[1].image}/>
      </AvatarGroup>
    </Grid>
    <Grid item xs={9}>
      <Typography noWrap variant="body2">{authors.map(a => a.name).join(", ")}</Typography>
      {aCaption && <Typography variant="caption" color={'textSecondary'}>{aCaption}</Typography>}
    </Grid>
  </Grid>
}

export default AuthorsAvatar