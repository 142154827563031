import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { BannerData } from '../../../../shared/constants';
import { ContactUsContainer } from "../../../../containers/ContactUsContainer";

export const CallToAction = ({ classes, onClickViewCourses }) => (
  <Grid container alignItems="flex-end" justify={'space-between'}>
    <Grid item xs={12} sm={5} md={5}>
      <Button aria-label={BannerData.callToAction.buttonLabel} fullWidth color="primary" variant="outlined" size={'large'} onClick={onClickViewCourses}>
        {BannerData.callToAction.buttonLabel}
      </Button>
    </Grid>
    <Grid item xs={12} sm={5} md={5}>
      <ChatContainer classes={classes}/>
    </Grid>
  </Grid>
);

const ChatContainer = ({ classes }) => (
  <Grid container className={classes.letsChatContainer}>
    <Grid item xs={12} className={classes.letsChatHeadline}>
      <Typography variant="body2" color="textSecondary">
        {BannerData.callToAction.chat.headline}
      </Typography>
    </Grid>
    <Grid item xs={12} md={12}>
      <ContactUsContainer label={BannerData.callToAction.chat.buttonLabel} isFullWidth={true} />
    </Grid>
  </Grid>
);
