import React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { TechnologiesData } from "../../shared/constants"
import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"

export default function Technologies() {
  const classes = useStyles();

  return <SectionP moreClasses={classes.section}>
    <Grid
      container
      direction="column"
      alignItems="center"
     
    >
      <Grid item >
        <SectionTitle color='textPrimary'>SPECIALIZE IN</SectionTitle>
      </Grid>
      <Grid item xs={12} style={{border: '0px solid blue'}}>
        <TechnologyDetails classes={classes} items={TechnologiesData} />
      </Grid>
    </Grid>
  </SectionP>
};

const TechnologyDetails = ({ classes, items }) => (
  <Grid container justify="center">
    <Grid item xs={10} md={10}>
      <Grid container spacing={4} className={classes.technologyItem}>
        {items.map((item, i) => (
          <Grid key={i} item xs={6} sm={3}>
            <img alt={item.name} src={item.image} key={i} className={classes.technologyImage} loading={"lazy"}/>
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: theme.palette.secondary.surface,
  },
  technologyImage: {
    height: '90%',
    width: '90%',
    [theme.breakpoints.down('md')]: {
      height: '100%',
      width: '100%'
    },
  },
  technologyItem: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    }
  }
}));
